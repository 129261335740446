export const primary = "#03B585";
export const secondaryLight = "#F7FAFA";
export const stroke = "#E7EFEC";
export const whisperWhite = "#F5F5F5";
export const forestGreen = "#39403C";
export const pastelGreen = "#CDDCC9";
export const mintCream = "#EFF7F4";
export const shadowGray = "#6F7673";
export const lightSilver = "#E0E0E0";
export const crimsonRed = "#F03C3C";
export const silverMistColor = "#F1F1F1";
export const white = "#FFFFFF";
export const lightAlabasterColor = "#F0F0F0";
export const softGray = "#E9E9E9";

export const graphiteGrayColor = "#33383D";
export const errorColor = "#F03C3C";
export const primaryColor = "#03B585";
export const silverSageColor = "#E7EFEC";
export const fireEngineRedColor = "#E95050";
